<style lang="less" scoped>

  @import "../../assets/css/variables";

  .userset-content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .user-info {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    > .user-image-wrapper {
      margin: 25px auto 15px auto;
      height: 100px;
      width: 100px;
      border-radius: 50px;
      box-shadow: 0 0 5px #999;
      overflow: hidden;
      background-color: #fff;
      > .user-image {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: contain;
      }
    }
  }

  .el-menu-item {
    padding-left: 40px !important;
  }

  .user-content {
    flex: 1;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
  }

</style>

<template :is="view">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/ucenter/userinfo' }">个人设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix">
      <el-tabs v-model="selectedIndex" @tab-click="handleSelect">
        <el-tab-pane label="用户资料" name="1"></el-tab-pane>
        <el-tab-pane label="头像设置" name="2"></el-tab-pane>
        <el-tab-pane label="修改密码" name="3"></el-tab-pane>
      </el-tabs>
      <!-- <el-row class="nav-menu">
         <el-col>
           <el-menu :default-active="selectedIndex" @select="handleSelect" class="el-menu">
             <el-menu-item index="1"><i class="el-icon-information"></i>用户资料</el-menu-item>
             <el-menu-item index="2"><i class="ion ion-person" style="font-size: 19px;margin-right: 8px;"></i>头像设置

             </el-menu-item>
             <el-menu-item index="3"><i class="ion ion-compose" style="font-size: 18px;margin-right: 7px;"></i>修改密码

             </el-menu-item>
           </el-menu>
         </el-col>
       </el-row>-->
      <div class="user-content">
        <router-view class="content"></router-view>
        <!--<my-footer class="footer-wrapper" style="margin-top: 30px"></my-footer>-->
      </div>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue';
  import logoBox from '../common/logo';
  import Footer from '../common/footer-justcr.vue';

  //webpack 会将此转为base64 然后就可以用了，src一个静态本地想对路径图片的时候会被webpack url-loader拦截
  import defaultImg from '../../assets/images/defaultImg.jpg';
  import {mapGetters, mapActions} from "vuex";
  import {API_ROOT} from "../../config";

  //Vue.component('search-box', SearchBox)
  export default {
    beforeRouteEnter(to, from, next) {
      next();
    },

    data() {
      return {
        selectedIndex: "1"//左边侧选中菜单
        // userImage: defaultImg
      }
    },
    components: {
      'my-footer': Footer
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      ),
      userImage(){
        return this.user.imageUrl ? API_ROOT + this.user.imageUrl : defaultImg;
      }
    },
    methods: {
      /**
       * 当菜单被选中时触发
       * @param key
       * @param keyPath
       */
      handleSelect() {
        if (this.selectedIndex == 3) {
          window.router.replace({path: 'editpwd'});
        }
        else if (this.selectedIndex == 2) {
          window.router.replace({path: 'headerimage'});
        }
        else {
          window.router.replace({path: 'userinfo'});
        }
      },
      /**
       *  根据地址栏路径初始化被选中的nav下标
       * @param currentRoute Router
       */
      initSelectedIndex(currentRoute){
        if (currentRoute.fullPath.indexOf('/editpwd') > -1) {
          this.selectedIndex = '3';
        }
        else if (currentRoute.fullPath.indexOf('/headerimage') > -1) {
          this.selectedIndex = '2';
        }
        else {
          this.selectedIndex = '1';
        }
      }
    },
    created(){
      const currentRoute = window.router.currentRoute;
      this.initSelectedIndex(currentRoute);
    },
    watch: {
      user(newVal){
        if (newVal) {
          this.userImage = API_ROOT + newVal.imageUrl;
        }
      },
      '$route': function () {
        const currentRoute = window.router.currentRoute;
        this.initSelectedIndex(currentRoute);
      }
    }
  }

</script>
